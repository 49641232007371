<template>
  <v-container id="IotEdgeCreateView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">Create IoT Edge</div>
            <v-breadcrumbs
              class="px-0 py-2"
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            :disabled="!enable_create_btn"
            @click.prevent="createNewIotEdgeBtnClicked"
          >
            Save
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Site Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Site Information</span
        >
      </v-col>
      <v-col cols="4">
        <v-select
          dense
          outlined
          clearable
          label="Site Name"
          item-text="siteName"
          item-value="_id"
          :items="site_list"
          v-model="selectedSite"
        >
          <template v-slot:item="{ item }">
            <div class="py-3">
              {{ item.siteName }}
            </div>
            <div
              v-if="item._id === last_site_item._id"
              v-intersect.quiet="onIntersect"
            ></div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
    </v-row>

    <!-- Iot Edge Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Iot Edge Information</span
        >
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          clearable
          label="IoT Edge Name"
          placeholder="IoT Edge Name"
          v-model="iotEdgeName"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          :filled="!isEnableHaMode"
          :disabled="!isEnableHaMode"
          clearable
          persistent-hint
          label="Stand by IoT Edge Name"
          placeholder="Stand by IoT Edge Name"
          hint="*High Availability mode should be enable"
          v-model="iotStandbyName"
        />
      </v-col>
      <v-col cols="4">
        <v-checkbox
          class="mt-1"
          hide-details
          label="Enable High Availability Mode ( HA )"
          v-model="isEnableHaMode"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
    </v-row>

    <!-- Module Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Module Information</span
        >
      </v-col>
    </v-row>
    <CreateEdgeModuleCell
      v-for="(edge_module, index) in edge_modules"
      :key="index"
      :v-bind="edge_module"
      :site_id="selectedSite"
      :sequence="index + 1"
    />
    <!-- New Module Button -->
    <v-row>
      <v-col cols="12" class="d-flex justify-center my-4">
        <v-btn
          small
          depressed
          outlined
          color="primary"
          @click="appendCreateModuleObject"
        >
          <v-icon left> mdi-plus </v-icon>
          ADD MORE MODULE
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CreateEdgeModuleCell from "@/components/cells/CreateEdgeModuleCell";

export default {
  name: "IotEdgeCreateView",
  components: {
    CreateEdgeModuleCell,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "IoT Edges",
          exact: true,
          disabled: false,
          to: { name: "edge" },
        },
        {
          text: "Create New IoT Edge",
          exact: true,
          disabled: true,
          to: { name: "edge-create" },
        },
      ],
      selectedSite: null,
      iotEdgeName: null,
      iotStandbyName: null,
      isEnableHaMode: false,
    };
  },
  computed: {
    ...mapState({
      site_list: (state) => state.site.site_list,
      total_sites: (state) => state.site.total_sites,
      current_page: (state) => state.site.current_page,
      edge_modules: (state) => state.edge.edge_modules,
    }),
    enable_create_btn: function () {
      if (this.isEnableHaMode) {
        // IF : HA mode enable -> check iotStandbyName
        return Boolean(
          this.selectedSite && this.iotEdgeName && this.iotStandbyName
        );
      } else {
        return Boolean(this.selectedSite && this.iotEdgeName);
      }
    },
    last_site_item: function () {
      if (this.site_list && this.site_list.length > 0) {
        const tmp_arr = this.site_list.slice(-1);
        return tmp_arr[0];
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      requestGetSiteList: "requestGetSiteList",
      requestCreateNewIotEdge: "requestCreateNewIotEdge",
    }),
    ...mapMutations({
      resetSiteState: "RESET_SITE_STATE",
      setFilterPage: "SET_SITE_FILTER_PAGE",
      appendCreateModuleObject: "APPEND_CREATE_MODULE_OBJECT",
    }),
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.site_list.length < this.total_sites) {
        this.setFilterPage(this.current_page + 1);
        this.requestGetSiteList();
      }
    },
    async createNewIotEdgeBtnClicked() {
      if (this.enable_create_btn) {
        const params = {
          siteId: this.selectedSite,
          edgeName: this.iotEdgeName,
          edgeStandbyName: this.iotStandbyName,
          enabledHA: this.isEnableHaMode,
          modules: this.edge_modules,
        };
        await this.requestCreateNewIotEdge(params);
      }
    },
  },
  async beforeMount() {
    await this.requestGetSiteList();
  },
  beforeRouteLeave(to, from, next) {
    this.resetSiteState();
    next();
  },
};
</script>
